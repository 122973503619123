import React from "react";
import { graphql, StaticQuery } from "gatsby";
import { Helmet } from 'react-helmet';

class HeadMeta extends React.Component {
  render() {
    const { data, pageTitle, pageDescription, socialTitle, socialDescription, socialImage } = this.props
    const global = data.allSocialJson.edges[0].node

    const socialTitleResult = socialTitle || global.socialTitle
    const socialDescriptionResult = socialDescription || global.socialDescription
    const socialImageResult = socialImage || global.socialImage

    return (
      <Helmet
        title={pageTitle}
        meta={[
          {
            name: 'description',
            content: pageDescription,
          },
          {
            itemprop: 'title',
            content: pageTitle,
          },
          {
            itemprop: 'description',
            content: pageDescription,
          },
          {
            property: 'og:type',
            content: 'website'
          },
          {
            property: 'og:url',
            content: 'http://www.tonit.com'
          },
          {
            property: 'og:title',
            content: socialTitleResult
          },
          {
            property: 'og:description',
            content: socialDescriptionResult
          },
          {
            property: 'og:image',
            content: `http://www.tonit.com${socialImageResult}`
          },
          {
            name: 'twitter:card',
            content: 'summary_large_image'
          },
          {
            name: 'twitter:title',
            content: socialTitleResult
          },
          {
            name: 'twitter:description',
            content: socialDescriptionResult
          },
          {
            name: 'twitter:image',
            content: `http://www.tonit.com${socialImageResult}`
          },
          {
            name: 'viewport',
            content: 'content=width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0'
          }
        ]}
      />
    )
  }
}


export default props => (
  <StaticQuery
    query={graphql`
    query {
      allSocialJson {
        edges {
          node {
            socialTitle
            socialDescription
            socialImage
          }
        }
      }
    }
`}
    render={(data) => <HeadMeta data={data} {...props} />}
  />
)